export const StagesHelper = {
  Variables: null,
  GetEnv: () => {
    switch (process.env.REACT_APP_STAGE) {
      case '%npm_config_stage%':
        return 'production';
      case 'local':
        return 'local';
      case 'development':
        return 'development';
      case 'staging':
        return 'staging';
      case 'production':
        return 'production';
      default:
        return 'production';
    }
  },
  Api: {
    Account: () => StagesHelper.Variables.AccountHost,
    Auth: () => StagesHelper.Variables.AccountHost + '/api',
    Apsiyon: () => StagesHelper.Variables.ApsisHost + '/api',
    Corporate: () => StagesHelper.Variables.CorporateHost + '/api',
    ApsiyonBulur: () => StagesHelper.Variables.ApsiyonBulurHost,
  },
  Link: {
    Apsiyon: () => StagesHelper.Variables.ApsiyonHost,
    Apsis: () => StagesHelper.Variables.ApsiyonHost + '/apsis',
    Account: () => StagesHelper.Variables.ApsiyonHost + '/account',
    Corporate: () => StagesHelper.Variables.CorporateHost,
    Cdn: () => StagesHelper.Variables.CdnHost,
  },
};
