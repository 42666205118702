import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css'; // Antd Styles
import 'react-perfect-scrollbar/dist/css/styles.css';
import axios from 'axios';
import { StagesHelper } from '@Shared/Helpers/Env.helper';
// Proje ayağa kaldırılırken REACT_APP_NAME environment oluşturulur.
// burada environment key Ract.lazy ile import ediliyor.
// Bunun sebebi diğer App'ler build ya da dev build esnasında bundle'a dahil edilmememsi.
const DefaultProject = React.lazy(() =>
  import(`./@Projects/${process.env.REACT_APP_NAME}`),
);

axios.get('/variables.json').then((res) => {
  StagesHelper.Variables = res.data;
  ReactDOM.render(
    <React.StrictMode>
      <Suspense
        fallback={
          <div
            style={{
              height: '100vh',
              width: '100vw',
              backgroundColor: '#EEEFF1',
            }}
          ></div>
        }
      >
        <DefaultProject />
      </Suspense>
    </React.StrictMode>,
    document.getElementById('root'),
  );
});
